<template>
  <div class="business-wrapper">
    <headerCom></headerCom>
    <!-- <bannerCom></bannerCom> -->

    <div class="block banner">
      <div class="banner_titile">
        <h3>业务领域 </h3>
        <p>茂恒会计师事务所，会计审计事务行业的领先品牌，专业和优质的服务是我们的成功密码！</p>
      </div>
      
    </div>

    <div class="content">
      <!-- <h2>
        <p style="transform: translateY(5px);height: 30px;width: 100%;top: auto;background-color: #daf1f1;"><span>鉴证服务</span></p>
      </h2> -->

      <ul>
        <!-- <p style="width: 50px;height: 3px;background: #177790;"></p> -->
        <li @click="goTopage('jzfw')" :class="[$route.query.title=='jzfw'?'active':'']">鉴证服务</li>
        <li @click="goTopage('jjzrsj')" :class="[$route.query.title=='jjzrsj'?'active':'']">经济责任审计</li>
        <li @click="goTopage('glzx')" :class="[$route.query.title=='glzx'?'active':'']">管理咨询</li>
        <li @click="goTopage('gcjssj')" :class="[$route.query.title=='gcjssj'?'active':'']">工程结算审计</li>
        <li @click="goTopage('yzqyzb')" :class="[$route.query.title=='yzqyzb'?'active':'']">验证企业资本</li>
        <li @click="goTopage('kjfw')" :class="[$route.query.title=='kjfw'?'active':'']">会计服务</li>
        <li @click="goTopage('cwjl')" :class="[$route.query.title=='cwjl'?'active':'']">财务监理</li>
        <li @click="goTopage('jzdc')" :class="[$route.query.title=='jzdc'?'active':'']">尽职调查</li>
      </ul>
      <div class="body">
        <p v-if="$route.query.title=='jzfw'">鉴证业务是指注册会计师对鉴证对象信息提出结论，以增强除责任方之外的预期使用者对鉴证对象信息信任程度的业务。鉴证业务包括历史财务信息审计业务、历史财务信息审阅业务和其他鉴证业务。执业依据是《中国注册会计师审计准则》。</p>
        <p v-if="$route.query.title=='jjzrsj'">经济责任审计，是指为企业高管在本单位任职期间，对其管辖范围内贯彻执行企业的经济方针政策、经济政策落实结果、经济廉洁等有关经济活动应当履行的职责进行专项审计。</p>
        <p v-if="$route.query.title=='glzx'">管理咨询以深入企业为基础，并且和企业管理人员密切结合，应用科学的方法，找出企业存在的主要问题，进行定量和确有论据的定性分析，查出存在问题的原因，提出切实可行的改善方案，进而指导实施方案，使企业的运行机制得到改善，提高企业的管理水平和经济效益。</p>
        <p v-if="$route.query.title=='gcjssj'">工程结算审计是对施工单位在工程竣工或年度终了时，与建设单位所办理的工程价款结算的审计。其主要内容：(1)中间结算(含跨年)检查工程形象进度的工程量，施工产值和预算成本是否正确、真实、本期应结工程价款净值是否与本期实际完成的施工产值或结算价值，扣除预收工程款、抵扣备料款、甲方材料款以及其他应扣款后的余额相符；(2)竣工结算，应审查竣工质量验收、竣工决算的合规性，未完部分尾工预提费用的合理性以及双方经济往来是否清楚，有无违反结算纪律行为。</p>
        <p v-if="$route.query.title=='yzqyzb'">资本验证是指注册会计师依法接受委托，对被审验单位注册资本的实收情况或注册资本及实收资本的变更情况进行审验，并出具验资报告。验资分为设立验资和变更验资。设立验资是指注册会计师对被审验单位申请设立登记时的注册资本实收情况进行的审验。变更验资是指注册会计师对被审验单位申请变更登记时的注册资本及实收资本的变更情况进行的审验。</p>
        <p v-if="$route.query.title=='kjfw'">会计服务为各类企业提供全方位的、专业化的财税服务，解决企业财务、税务、工商、年检等各类“疑难杂症”，有效地帮助企业节省时间、精力和资金。本着订制的态度以专业的财会技能为社会创新保驾护航。</p>
        <p v-if="$route.query.title=='cwjl'">财务监理工作计划既要遵循审计准则的规定要求，也要符合单项财力投资项目的具体情况，更要符合国家有关财力投资项目管理要求。对委托方提供的资料、数据或采取必要的程序通过听取介绍、实地勘察、调研、询查、复核等方式，对有关情况进行了解与核实。对照指标和标准进行资料收集，对所掌握的有关数据与资料进行分类、整理和对照指标和标准进行分析与判断，提出审计结论意见（建议）。</p>
        <p v-if="$route.query.title=='jzdc'">尽职调查指在收购过程中收购者对目标公司的资产和负债情况、经营和财务情况、法律关系以及目标企业所面临的机会与潜在的风险进行的一系列调查。是企业收购兼并程序中最重要的环节之一，也是收购运作过程中重要的风险防范工具。调查过程中通常利用管理、财务、税务方面的专业经验与专家资源，形成独立观点，用以评价购并优劣，作为管理层决策支持。</p>
      </div>
    </div>
    <el-footer>
      <footerCom></footerCom>
    </el-footer>
  </div>
</template>

<script>
import headerCom from './children/header.vue' 
// import bannerCom from './children/banner.vue' 
import footerCom from './children/footer.vue' 
  export default {
    components: { headerCom,footerCom },
    data() {
      return {
       
      }
    },
    created(){

    },
    mounted() {
      // 实现swiper双向控制
      this.$nextTick(() => {

        
      })
    },
    methods: {
      goTopage(title){
        this.$router.push({'path':'/business',query:{title:title}})
      },
    }
  }
</script>


<style lang="scss">
.business-wrapper {
  
  .banner {
    background-image: url("../assets/images/sub-banner.jpg");
    background-size: 100% auto;
    background-attachment:fixed;
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center -130px;
    height:500px;margin: 0;overflow: hidden;position: relative;
    .banner_titile {
      position: absolute;top: 180px;left: calc(50% - 600px);width: 1200px;z-index: 100;
      h3 {
        color: #fff;font-weight: 700;margin-bottom: 25px;font-size: 60px;letter-spacing: 5px;overflow: hidden;
      }
      p {
        color: #fff;
        font-size: 22px;
        letter-spacing: 2px;
        margin-bottom: 90px;
      }
    }
  }
  

  
  .content{
    width: 1200px;
    overflow: hidden;
    margin: 100px auto;
    // h2 {
    //   float: left;height: 52px;color:#333;font-weight: bold;
    //   font-size: 38px;
    //   text-align: center;
    // }
    ul {
      float: left;
      margin: 0;overflow: hidden;
      li {
        font-size: 16px;
        color: #333;
        padding: 10px 30px;
        cursor: pointer;
        margin: 5px 0;;
        border-radius: 30px;
        letter-spacing: 1px;
        
        text-align: center;
        width: 128px;
        // transition: all .2s ease;
        &:hover {
          transition: all .2s ease;
          background-color: #177790;
          color: #fff;
          list-style: disc inside;
        }
        &.active {
          background-color: #177790;
          color: #fff;
          list-style: disc inside;
        }
      }
    }
    .body {
      float: right;
      width: 850px;
      // border: 1px solid #ccc;
      height: 500px;
      padding: 25px;
      background: #E0EBED;
      p {
        line-height: 30px;
        text-indent: 34px;
      }
    }
  }
  .el-footer {
    width:100%;
    padding: 0;
    height: 300px !important;
    background: #181818;
  }
}

@media screen and (max-width:991px) {

  .business-wrapper {
    .banner{
      height: 350px;
      .banner_titile {
        width: 100%;
        top: 120px;
        left: 0;
        padding: 0 30px;
        box-sizing: border-box;
        h3 {
          font-size: 30px;
        }
        p {
          font-size: 20px;
        }
      }
    } 
    .content {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 10px;
      ul {
        width: 100%;
        overflow: hidden;
        margin: 50px 0;
        li {
          float: left;
          padding: 8px 6px;
          margin: 0 2px;
          width: 115px;
          &:hover {
            list-style: none;
          }
          &.active {
            list-style: none
          }
        }
        
      }
      .body {
        width: 100%;
        box-sizing: border-box;
        height: auto;
        padding-bottom: 100px;
      }
    }
  }
}
</style>


